declare global {
  interface Window { 
    ERROR_MESSAGE: string
    ERROR_NAME: string
  }
}

import { GoogleTagManager } from "../utils/googleTagManager";
import http from '../utils/http'

GoogleTagManager.init({
  gtmId: window.config.env.gtmID
})

document.addEventListener('DOMContentLoaded', async () => {
  let currentUser

  try {
    const sessionResponse = await http.get('/get_session_status.json', {})
    const sessionStatus = await sessionResponse.json()
  
    if (sessionStatus) {  
      const userResponse = await http.get(`/api/users/${sessionStatus.user_id}.json`, {})
      currentUser = await userResponse.json()
    }
  } catch (error) {
    console.error(error)
    window.Sentry?.captureException(error)
  }

  GoogleTagManager.trackError({
    name: window.ERROR_NAME,
    type: 'response',
    message: window.ERROR_MESSAGE,
    details: document.title,
  }, currentUser)
})
