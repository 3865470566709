import TagManager from "react-gtm-module";
import { isEqual } from "lodash";
import kebabalize from '../kebabalize'
import { buildPageViewAttrs, buildCommonAttrs } from "./helper";
import { ErrorTrackingAttrs } from './types'

export const GoogleTagManager = (function() {
  const init = ({ gtmId }) => {
    TagManager.initialize({ gtmId });

    handleUncaughtErrors();
  }

  const trackPageView = (history, currentUser, currentStep, currentSection) => {
    try {
      const dataLayerArgs = buildPageViewAttrs('page_view', history, currentUser, currentStep, currentSection);

      const lastDataLayerEntry = window.dataLayer.slice(-1)[0]
      if (isEqual(dataLayerArgs.dataLayer, lastDataLayerEntry)) return; // avoid duplicate pageviews

      TagManager.dataLayer(dataLayerArgs);
    } catch (error) {
      console.error(error)
      window.Sentry?.captureException(error)
    }
  }

  const trackError = (errorAttrs: ErrorTrackingAttrs, currentUser = undefined) => {
    try {
      const commontAttrs = buildCommonAttrs('error', currentUser)
      const eventAttrs = {
        ...commontAttrs,
        error: errorAttrs
      }
  
      TagManager.dataLayer({ dataLayer: eventAttrs })
    } catch (error) {
      console.error(error)
      window.Sentry?.captureException(error)
    }
  }

  const parseErrorAttrs = (error: Error, errorType?: string): ErrorTrackingAttrs => {
    const { name, message, stack } = error;

    return {
      name: kebabalize(name),
      type: errorType || "response",
      message: message,
      details: stack,
    }
  }

  const handleUncaughtErrors = () => {
    window.addEventListener('error', (event) => {
      const errorAttrs = parseErrorAttrs(event.error)

      trackError(errorAttrs)
    })
  }

  return {
    init,
    trackPageView,
    trackError,
    parseErrorAttrs
  }
}());

