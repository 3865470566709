import { snakelizeKeys } from '../../utils';
import { HttpVerb, RequestParams } from './types'

const getCsrfToken = () => {
  const metaElement = document.querySelector('meta[name="csrf-token"]')

  if (metaElement instanceof HTMLMetaElement) {
    return metaElement.content
  }
}

const buildRequest = (
  url: string,
  method: HttpVerb,
  data: any,
  headers: any = null) => {
  const request: RequestParams = {
    method,
  }

  request.headers = headers || { 'Content-Type': 'application/json' }

  if (method !== 'GET') {
    request.headers['X-CSRF-Token'] = getCsrfToken();

    request.body = request.headers['Content-Type'] === 'application/json' ? JSON.stringify(snakelizeKeys(data)) : data
  }

  return fetch(url, request)
}

const http = {
  getCsrfToken: () => getCsrfToken(),
  get: (url, headers) => buildRequest(url, 'GET', headers),
  post: (url, data, headers) => buildRequest(url, 'POST', data, headers),
  put: (url, data, headers) => buildRequest(url, 'PUT', data, headers),
  del: (url, headers) => buildRequest(url, 'DELETE', headers)
}

export default http
